import React from 'react';
import { motion } from 'framer-motion';
import './About.scss';
import AppWrap from '../../wrapper/AppWrap';

const abouts = [
  {
    title: "Frontend Developer",
    description: "Crafting modern, clean code with seamless backend API integration.",
    imgUrl: "https://e1.pxfuel.com/desktop-wallpaper/766/945/desktop-wallpaper-custom-software-development-app-development.jpg"
  },
  {
    title: "Mobile App Developer",
    description: "Building user-friendly SaaS mobile applications with React Native and Expo.",
    imgUrl: "https://wallpaperaccess.com/full/3770579.jpg"
  },
  {
    title: "Backend Developer",
    description: "Expertise in Node.js and Spring Boot for robust server-side solutions.",
    imgUrl: "https://wallpapercave.com/wp/wp11543643.jpg"
  },
  {
    title: "Graphic Designer",
    description: "Creating engaging designs for social media platforms.",
    imgUrl: "https://st3.depositphotos.com/7809818/17430/v/450/depositphotos_174305654-stock-illustration-black-smartphone-with-facebook-on.jpg"
  },
];

const About = () => {
  return (
    <section>
      <div className="app__about" style={{ paddingTop: '80px' }}>
        <motion.h2
          className="head-text"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          Discover <span className="text-blue-600">My Skills</span>
        </motion.h2>

        <div className="app__profiles">
          {abouts.map((about, index) => (
            <motion.div
              key={about.title + index}
              className="app__profile-item"
              initial={{ opacity: 0, scale: 0.9 }}
              whileInView={{ opacity: 1, scale: 1 }}
              whileHover={{ scale: 1.05 }}
              transition={{ duration: 0.3 }}
            >
              <div className="app__profile-content">
                <img src={about.imgUrl} alt={about.title} className="app__profile-img" />
                <h3 className="bold-text">{about.title}</h3>
                <p className="p-text">{about.description}</p>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default AppWrap(About, 'about');